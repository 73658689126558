import React, { createContext, useState }  from 'react';
import { Fade } from 'react-slideshow-image';
import { PagingStar } from '../../../icons/PagingStar';
import { PagingArrow } from '../../../icons/PagingArrow';
import { useTrackingContext } from '../../../hooks/tracking/useTrackingContext';

// Create a context to hold the current slide index
export const FadeContext = createContext({});

export const FadeCarousel = ({ children, trackingData = {} }) => {
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const { trackContentAction } = useTrackingContext();

  const defaultTrackingData = {
    item_type: 'button',
    item_name: 'slide',
    action_type: 'select',
    action_value: 'slide',
    unit_name: 'splash',
    position_in_unit: 3,
    subunit_name: 'article_carousel',
    subunit_type: 'package',
    ...trackingData,
  };

  const indicators = () => (
    <li className="indicator">
      <PagingStar className="icon" />
    </li>
  );

  const prevArrow = (
    <button
      className="btnPaging btnPrev"
    >
      <PagingArrow ariaLabel="Previous slide" />
    </button>
  );

  const nextArrow = (
    <button className="btnPaging btnNext">
      <PagingArrow ariaLabel="Next slide" />
    </button>
  );

  const properties = {
    autoplay: false,
    transitionDuration: 500,
    indicators: indicators,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    onChange: (prev, next) => {
      setCurrentIndex(next);
      const action_value = prev < next ? 'right' : 'left';
      trackContentAction({
        ...defaultTrackingData,
        action_value,
        item_name: 'scroll'
      });
    }
  }

  return (
    <FadeContext.Provider value={{ currentIndex }}>
      <Fade {...properties}>
        {children}
      </Fade>
    </FadeContext.Provider>
  );
};

export default FadeCarousel;
